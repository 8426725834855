'use client';

import React, {useEffect} from 'react';

/**
 * A small component for lazy loading images using intersection observer API.
 * Put it into the root layout.
 * Usage:
 * <img
 *     className="lazy-load"
 *     src="small-image-path"
 *     data-src={`
 *     image-with-100vw 100w,
 *     image-with-400vw 400w,
 *     ....
 *     `}
 *   data-sizes="(max-width: 600px) 100vw, (max-width: 1080px) 50vw, 33vw"
 * />
 */
export function LazyImageLoader(): React.ReactElement {
  useEffect(() => {
    const applyLazyLoad = () => {
      const lazyImages =
        document.querySelectorAll<HTMLImageElement>('img.lazy-load');

      if ('IntersectionObserver' in window) {
        const lazyImageObserver = new IntersectionObserver(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (entries, _observer) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                const img = entry.target as HTMLImageElement;
                if (img.dataset.src) img.src = img.dataset.src;
                if (img.dataset.srcset) img.srcset = img.dataset.srcset;
                if (img.dataset.sizes) img.sizes = img.dataset.sizes;
                img.classList.remove('lazy-load');
                lazyImageObserver.unobserve(img);
              }
            });
          },
        );

        lazyImages.forEach(img => {
          lazyImageObserver.observe(img);
        });

        return () => {
          lazyImageObserver.disconnect();
        };
      } else {
        let lazyLoadThrottleTimeout: ReturnType<typeof setTimeout> | null =
          null;

        const lazyLoad = () => {
          if (lazyLoadThrottleTimeout) {
            clearTimeout(lazyLoadThrottleTimeout);
          }

          lazyLoadThrottleTimeout = setTimeout(() => {
            const scrollTop = window.pageYOffset;
            lazyImages.forEach(img => {
              if (img.offsetTop < window.innerHeight + scrollTop) {
                if (img.dataset.src) img.src = img.dataset.src;
                if (img.dataset.srcset) img.srcset = img.dataset.srcset;
                if (img.dataset.sizes) img.sizes = img.dataset.sizes;
                img.classList.remove('lazy-load');
              }
            });
            if (lazyImages.length === 0) {
              document.removeEventListener('scroll', lazyLoad);
              window.removeEventListener('resize', lazyLoad);
              window.removeEventListener('orientationChange', lazyLoad);
            }
          }, 20);
        };

        document.addEventListener('scroll', lazyLoad);
        (window as Window).addEventListener('resize', lazyLoad);
        (window as Window).addEventListener('orientationChange', lazyLoad);

        return () => {
          if (lazyLoadThrottleTimeout) {
            clearTimeout(lazyLoadThrottleTimeout);
          }
          document.removeEventListener('scroll', lazyLoad);
          window.removeEventListener('resize', lazyLoad);
          window.removeEventListener('orientationChange', lazyLoad);
        };
      }
    };

    let cleanup = applyLazyLoad();

    const mutationObserver = new MutationObserver(() => {
      if (cleanup) cleanup();
      cleanup = applyLazyLoad();
    });

    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      if (cleanup) cleanup();
      mutationObserver.disconnect();
    };
  }, []);

  return <></>;
}
