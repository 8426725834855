import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["LazyImageLoader"] */ "/vercel/path0/src/components/LazyImageLoader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/utils/smartlook.tsx");
