'use client';

import {useEffect} from 'react';
import Smartlook from 'smartlook-client';

const SmartlookProvider = () => {
  useEffect(() => {
    Smartlook.init('a7e976da5c73dc7a49852608d7d30067fa6faf6b');
  }, []);

  return null; // No UI, just initializes Smartlook
};

export default SmartlookProvider;
